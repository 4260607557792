<template>
    <div class="w-full">
      <div class="flex">
        <div class="bg-base-200 z-[999]" :class="[isInTop ? 'relative top-8 container mx-auto bg-opacity-50 rounded-xl' : 'animate-fade-down animate-alternate border-b-2 border-secondary fixed top-0 w-full']">
          <NavBar/>
        </div>
        <div class="absolute w-full flex flex-col">
          <slot></slot>
          <!--add right bottom whatsapp button-->
          <div class="fixed bottom-12 right-8">
            <a v-if="whatsapp" :href="'https://wa.me/'+whatsapp" target="_blank" class="btn btn-circle btn-ghost btn-md lg:hidden p-0">
              <Icon name="logos:whatsapp-icon" class="w-full h-full"/>
            </a>
          </div>
          <div>
            <Footer/>
          </div>
        </div>

      </div>
  
    </div>
  </template>
  <script setup lang="ts">
  const {whatsapp,banner, GetSiteSettings} = useSiteSettings()
  await GetSiteSettings()
  const isInTop = ref(true)
  const scrollHandler = () => {
    console.log(window.scrollY)
    if(window.scrollY > 100){
      isInTop.value = false
    } else {
      isInTop.value = true
    }
  }
  onMounted(() => {
    window.addEventListener('scroll', scrollHandler)
  })
  </script>
  