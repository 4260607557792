<script setup lang="ts">

const {title, description,
  facebook,
  twitter,
  instagram,
  whatsapp,
  GetSiteSettings} = useSiteSettings()

await GetSiteSettings()

if(description.value != null && description.value != '')
{
  title.value = title.value + ' | ' + description.value  
}

useHead({
  title: title.value,
  meta: [
    {
      name: 'description',
      content: description.value
    },
    {
      name: 'keywords',
      content: 'benimsaham, benim saham, benim, saham, '+title.value+', '+description.value
    }
  ]
})
definePageMeta({
    layout: 'homedefault',
})

const { data, status, error } = await requestWrapper().get(
  '/api/home/default')

const slogans = ref<any>([])
if (data && data.value?.data.sliders)
{
  var sliders = data.value.data.sliders
  
  sliders.forEach((item) => {
    //console.log(item)
    slogans.value.push({
      id: item.id,
      title: item.title,
      subtitle: item.subTitle,
      description: item.description,
      image: item.image
    })
  })
}
const selected = ref(1)
//intersection observer for video_[id] carousel items
const options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.5
}
const callback = (entries: any, observer: any) => {
  entries.forEach((entry: any) => {
    if (entry.isIntersecting) {
      entry.target.classList.remove('sm:rotate-3 md:rotate-3')
      selected.value = entry.target.id.split('video_')[1]
      console.log(selected.value)
    } else {
      entry.target.classList.add('sm:rotate-3 md:rotate-3')
    }
  })
}
var observer = null
onMounted(() => {
  observer = new IntersectionObserver(callback, options)
  for (let i = 0; i < 3; i++) {
    observer.observe(document.getElementById('video_' + i))
  }
})

</script>

<template>
  <div class="flex flex-col min-h-full gap-8">
    <div class="h-96 lg:h-[720px]">
      <CarouselHome :slogans="slogans" :upload-url="useRuntimeConfig().public.uploadUrl +'/images/home_defaults/'"/>
    </div>
    <h2 class="text-2xl font-bold text-center">Kayıtlı Maçlar</h2>
    <div class="carousel lg:grid lg:grid-flow-row lg:grid-rows-1 lg:grid-cols-5 gap-6 p-8 items-end">
      <div v-if="data" v-for="post, index in data.data.posts" :id="'video_'+index" class="carousel-item card shadow-xl image-full cursor-pointer w-full lg:h-[600px] z-0" 
      :class="[{'lg:h-[720px] lg:col-span-3 lg:animate-flip-up': selected == index}]"  @mouseover="selected=index" @click="navigateTo(`/post/${post.reference}`)">
          <figure><NuxtImg :src="useRuntimeConfig().public.uploadUrl+'/images/'+post.areaSlug+'/'+ post.image" class="object-center object-cover w-full h-full"alt="benimsaham" loading="lazy"/></figure>
          <div class="card-body justify-center items-center relative">
            <Icon name="material-symbols:play-circle-outline" class="absolute" size="48px" />
            <!-- mac saati ve tarih gorunmesin istiyorlar
            <div class="absolute bottom-4 pl-10 pr-10 text-center bg-black opacity-25" :class="{'hidden': selected !== index}">
              <h2 :class="{'animate-fade-left animate-delay-200': selected === index}" >{{ post.title }}</h2>
              <p :class="{'animate-fade-right animate-delay-200': selected === index}">{{ post.description }}</p>
            </div>
            -->
          </div>
      </div>
      <div v-else-if="status == 'pending'" v-for="index in 3" class="card shadow-xl image-full" :class="[index%2 == 0 ? '-rotate-3' : 'rotate-3']">
          <figure class="skeleton w-64 h-40"></figure>
          <div class="skeleton card-body">
            <h2 class="skeleton card-title"></h2>
            <p class="skeleton"></p>
          </div>
      </div>
      <div v-else-if="status == 'error'" class="card shadow-xl image-full">
          <div class="card-body">
            <h2 class="card-title">Error</h2>
            <p>{{ error }}</p>
            <p>{{ status }}</p>
            <p>{{ data }}</p>
          </div>
      </div>
      <div v-else class="w-full">
        <p>
          henüz bir video yok
        </p>
      </div>
      
    </div>
        
  </div>
</template>
