<template>
<div class="navbar">
  <div class="navbar-start">
    <div class="dropdown">
      <div tabindex="0" role="button" class="btn btn-ghost lg:hidden" @click="toggleDropdown">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
      </div>
      <ul tabindex="0" class="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52" :class="isDropdownOpen ? '' : 'hidden'" >
        <li>
          <NuxtLink to="/" :class="{'italic active' : $route.name == 'index'}" @click="toggleDropdown">Anasayfa</NuxtLink>
        </li>
        <li>
          <NuxtLink to="/services" :class="{'italic active' : $route.name == 'services'}" @click="toggleDropdown">Hizmetler</NuxtLink> 
        </li>
        <li>
          <NuxtLink to="/contact" :class="{'italic active' : $route.name == 'contact'}" @click="toggleDropdown">İletişim</NuxtLink>
        </li>
        <li v-if="reservation">
          <NuxtLink to="/reservation" :class="{'italic active' : $route.name == 'reservation'} " @click="toggleDropdown">Rezervasyon</NuxtLink>
        </li>
      </ul>
    </div>
    <div class="hidden lg:block">
      <NuxtLink to="/" class="btn btn-ghost normal-case h-16"><NuxtImg :src="useRuntimeConfig().public.uploadUrl+'/images/site/site_logo.png'" class="h-full" loading="lazy"/></NuxtLink>
    </div>
  </div>
  <div role="tablist" class="navbar-center">
    <div class="hidden lg:flex lg:tabs lg:tabs-bordered font-bold text-neutral-900">
      <NuxtLink role="tab" to="/services" class="tab " :class="{'tab-active' : $route.name == 'services'} ">Hizmetler</NuxtLink> 
      <NuxtLink role="tab" to="/contact" class="tab " :class="{'tab-active' : $route.name == 'contact'} ">İletişim</NuxtLink> 
      <NuxtLink v-if="reservation" role="tab" to="/reservation" class="tab" :class="{'tab-active' : $route.name == 'reservation'} ">Rezervasyon</NuxtLink>
    </div>
    <div class="lg:hidden flex flex-col">
      <div :class="{'hidden': isSearchClicked}">
        <NuxtLink to="/" class="btn btn-ghost normal-case "><NuxtImg :src="useRuntimeConfig().public.uploadUrl+'/images/site/site_logo.png'" class="h-full" loading="lazy"/></NuxtLink>
      </div>
     
    </div>
  </div>
  <div class="navbar-end ">
    <!--social media icons and link-->
    <div class="flex flex-row gap-2 p-2">
      <a v-if="facebook" :href="'https://www.facebook.com/'+facebook" target="_blank" class="btn btn-ghost btn-circle btn-sm p-0"><Icon name="logos:facebook" class="w-8 h-8"/></a>
      <a v-if="instagram" :href="'https://www.instagram.com/'+instagram" target="_blank" class="btn btn-ghost btn-circle btn-sm p-0"><Icon name="skill-icons:instagram" class="w-8 h-8"/></a>
      <a v-if="twitter" :href="'https://www.x.com/'+twitter" target="_blank" class="btn btn-ghost btn-circle btn-sm p-0"><Icon name="devicon:twitter" class="w-8 h-8"/></a>
      <a v-if="whatsapp" :href="'https://wa.me/'+whatsapp" target="_blank" class="btn btn-circle btn-ghost btn-sm hidden lg:block p-0">
        <Icon name="logos:whatsapp-icon" class="w-8 h-8"/>
      </a>
    </div>
    <div class="join hidden lg:flex text-neutral-900">
      <input v-model="video_reference" class="input input-secondary input-bordered input-ghost join-item font-bold placeholder-gray-700" placeholder="Video Ara"/>
      <button class="btn btn-secondary  join-item rounded-r-xl" @click="searchVideo">Ara</button>
    </div>
    <div class="lg:hidden join">
      <input v-model="video_reference" class="input input-secondary input-bordered input-ghost join-item font-bold placeholder-gray-700" :class="[isSearchClicked? 'animate-fade-left': 'hidden']" placeholder="Video Ara"/>
      <button class="btn btn-ghost join-item" @click="searchVideo">
        <Icon name="eva:search-outline" class="w-6 h-6"/>
      </button>
    </div>
  </div>
</div>
</template>

<script setup lang="ts">
const isDropdownOpen = ref(false)
const isSearchClicked = ref(false)
const video_reference = ref('')

const{reservation, 
  facebook,
  twitter,
  instagram,
  whatsapp,
  GetSiteSettings} = useSiteSettings();

await GetSiteSettings();

const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value
}

const searchVideo = async () => {
  if(video_reference.value.length > 0){
    //request to api for search video with video_reference value 
    var { status, error, refresh } = await requestWrapper().get('/api/post/'+video_reference.value)
    if(status.value == 'success'){
      navigateTo(`/post/${video_reference.value}`)
      video_reference.value = ''
    }
    else{
      console.log(error.value)
      alert('Video bulunamadi')
    }
    isSearchClicked.value = false

  }
  else{
    isSearchClicked.value = !isSearchClicked.value
  }
}

</script>