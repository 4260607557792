export function useSiteSettings(){  
    const title = ref('');
    const description = ref('');
    const logo = ref('');
    const icon = ref('');
    const banner = ref('');
    const background = ref('');
    const watermark = ref('');
    const reservation = ref(false);
    const facebook = ref('');
    const twitter = ref('');
    const instagram = ref('');
    const whatsapp = ref('');

    const GetSiteSettings = async () => {
        
       
        const { data, status, error, refresh } = await requestWrapper().get(
            '/api/site/settings'
        );
        var res = data.value;
        if(res.success){
            title.value = res.data.title;
            description.value = res.data.description;
            logo.value = res.data.logo;
            icon.value = res.data.icon;
            background.value = res.data.background;
            banner.value = res.data.banner;
            watermark.value = res.data.watermark;
            reservation.value = res.data.reservation === 1 ? true : false;
            facebook.value = res.data.facebook;
            twitter.value = res.data.twitter;
            instagram.value = res.data.instagram;
            whatsapp.value = res.data.whatsapp;
        }
        //console.log(data.value);
        
        return { data, status, error, refresh };
    };

    const UpdateSiteSettings = async () => {
        const { status, error, refresh } = await requestWrapper().post(
            '/api/site/settings',
            {
                title: title.value,
                description: description.value,
                logo: logo.value,
                icon: icon.value,
                background: background.value,
                watermark: watermark.value,
                reservation: reservation.value ? 1 : 0,
                banner: banner.value,
                homePageTag:"default",
                facebook: facebook.value,
                twitter: twitter.value,
                instagram: instagram.value,
                whatsapp: whatsapp.value
            }
        );
        if(status.value === 'success'){
            useToast().success('Settings updated successfully');
        }
        if(status.value === 'error'){
            useToast().error('Error updating settings');
        }
        return { status, error, refresh };
    }

    return {
        title,
        description,
        logo,
        icon,
        banner,
        background,
        watermark,
        reservation,
        facebook,
        twitter,
        instagram,
        whatsapp,
        GetSiteSettings,
        UpdateSiteSettings

    };
}