<template>
    <div class="w-full">
      <div class="flex">
        <div class="bg-primary z-[1]" :class="[isInTop ? 'relative top-8 container mx-auto bg-opacity-50 rounded-xl' : 'animate-fade-down animate-alternate fixed top-0 w-full']">
          <NavBar/>
        </div>
        <div class="absolute w-full flex flex-col">
          <div class="relative h-80 lg:h-[500px] bg-base-200 bg-opacity-50 image-full w-full">
            <svg class="absolute inset-0 w-full h-full z-[0]">
                <defs>
                    <pattern id="circles" x="0" y="0" width="4" height="4" patternUnits="userSpaceOnUse">
                        <circle cx="2" cy="2" r="1.5" fill="currentColor" class="bg-transparent" opacity="0.5"/>
                    </pattern>
                </defs>
                <rect width="100%" height="100%" fill="url(#circles)" />
            </svg>
            <NuxtImg class="w-full h-full object-center object-cover" loading="lazy" :src="useRuntimeConfig().public.uploadUrl +'/images/site/'+banner" />
            <!--page title and description-->
            <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-white">
              <h1 class="text-4xl lg:text-6xl font-bold">{{ $route.meta.title }}</h1>
              <p class="text-lg lg:text-2xl font-semibold">{{ $route.meta.description }}</p>
            </div>
          </div>
          <slot></slot>
          <!--add right bottom whatsapp button-->
          <div class="fixed bottom-12 right-8 z-50">
            <a v-if="whatsapp" :href="'https://wa.me/'+whatsapp" target="_blank" class="btn btn-circle btn-ghost btn-md lg:hidden p-0">
              <Icon name="logos:whatsapp-icon" class="w-full h-full"/>
            </a>
          </div>
          <div>
            <Footer/>
          </div>
        </div>

      </div>
  
    </div>
  </template>
  <script setup lang="ts">
  const {whatsapp,banner, GetSiteSettings} = useSiteSettings()
  await GetSiteSettings()
  const isInTop = ref(true)
  const scrollHandler = () => {
    console.log(window.scrollY)
    if(window.scrollY > 100){
      isInTop.value = false
    } else {
      isInTop.value = true
    }
  }
  onMounted(() => {
    window.addEventListener('scroll', scrollHandler)
  })
  </script>
  